import { Block, ButtonLink, Container, createPage, For, If, Slot, Title } from 'react-commons';

import HomeModel from '@/lib/drupal/models/Home';
import { useIsPremiumUser } from '@/lib/hooks/useIsPremiumUser';
import { useMetaProps } from '@/lib/hooks/useMetaProps';
import GamesModel, { DailyGameFields, DailyGameUpsell, ExtendedGameData, FullGameData } from '@/lib/drupal/models/Games';
import { useAuthStore } from '@/lib/drupal/stores/auth';

import FluidLayout from '@/layouts/FluidLayout';
import Meta from '@/components/Meta';
import { BonusDailyGameTile, DailyGameTile, MediaDailyGameTile } from '@/components/DailyGameTile';
import { DailyGameTileContainer, FlexDailyGameTileContainer } from '@/components/DailyGameTileContainer';
import PerDevice from '@/components/PerDevice';
import DownloadsUpsellTile from '@/components/DownloadsUpsellTile';
import DailyHero, { DailyHeroGameTile } from '@/components/DailyHero';

import style from './index.module.scss';
import { DailyArchiveUpsellTile } from '@/components/DailyArchiveUpsellTile';
import PlaywireUnit from '@/components/playwire/PlaywireUnit';
import { dateToDailyFields } from '@/lib/util/archiveHelpers';
import { addWeeks } from 'date-fns';
import Link from 'next/link';
import { useAppStore } from '@/lib/stores/app';
import PromoTile from '@/components/PromoTile';
import { useAnnualPlanPromo } from '@/lib/util/useAnnualPlanPromo';

interface IndexPageProps {
  games: ExtendedGameData[]
  downloadGames: ExtendedGameData[]
  featuredDailyGame: FullGameData
  dailyJigsaw: FullGameData
  upsell: DailyGameUpsell
  bonusDailyGames: {
    game: ExtendedGameData
    archiveDate: DailyGameFields
  }[]
}

export default createPage<IndexPageProps>('IndexPage', { style }, function IndexPage ({}, props) {
  const [ isPremiumUser ] = useIsPremiumUser();
  const [ authState ] = useAuthStore();
  const [ appState ] = useAppStore();
  
  const metaProps = useMetaProps({
    title: 'Games | FREE Online Games & Download Games | Play Games on Shockwave.com',
    description: 'Play over 1,800 free online games. Shockwave.com offers the best puzzle games, cooking games, dress up games, car racing games, and more. New games every day!',
    keywords: 'Play Games, Online Games, Games',
    ldjson: [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        'url': 'https://www.shockwave.com',
        'name': 'Shockwave.com',
        'logo': 'https://www.shockwave.com/images/shockwave.svg',
        'email': 'support@shockwavehelp.zendesk.com',
        'contactPoint': [
          {
            '@type': 'ContactPoint',
            'contactType': 'customer service',
            'url': 'https://shockwavehelp.zendesk.com/hc/en-us/requests/new',
            'email': 'support@shockwavehelp.zendesk.com'
          }
        ]
      },
      {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        'publisher': {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          'name': 'Shockwave.com',
          'url': 'https://www.shockwave.com/',
          'alternateName': [
            'Shockwave Games',
            'Shockwave LLC',
            'Supercalifragalistic Games',
          ],
          'sameAs': [
            'https://x.com/Shockwavedotcom',
          ],
          'parentOrganization': {
            '@context': 'http://schema.org',
            '@type': 'Organization',
            'name': 'Shockwave LLC',
            'url': 'https://www.shockwave.com/',
            '@id': 'https://www.shockwave.com/',
            'sameAs': [
              'https://x.com/Shockwavedotcom',
            ],
            'logo': {
              '@context': 'http://schema.org',
              '@type': 'ImageObject',
              'url': 'https://www.shockwave.com/images/shockwave.svg',
              'height': 57,
              'width': 162
            }
          }
        }
      },
      {
        '@type': 'Game',
        '@context': 'http://schema.org',
        'inLanguage': [ 'en-US' ],
        'description': 'Shockwave Daily Games'
      }
    ]
  });

  const { isActive: isPromoActive } = useAnnualPlanPromo();

  return (
    <FluidLayout
      useAdColumn
      usePromoBanner
      pageName='index_modern'
    >

      {/* Meta information */}
      <Meta 
        {...metaProps}
        sitemapProps={{
          priority: '1.0',
          changefreq: 'daily',
        }}
      />
  
      {
        If(props.featuredDailyGame, () => (
          <section>
            <DailyHero>
              <DailyHeroGameTile 
                featuredGame={props.featuredDailyGame} 
                isPremiumUser={isPremiumUser}
              />
            </DailyHero>
          </section>
        )).EndIf()
      }

      <section>
        <Container>
          {/* Featured Games */}
          <Block>
            <PerDevice useDisplay>

              {/* Mobile */}
              <Slot name='mobile'>
                <Block>
                  <DailyGameTileContainer>
                    {
                      For(props.games.slice(0,2), (game, index: number) => (
                        <DailyGameTile
                          key={index} 
                          game={game}
                          color={game.highlightColor}
                          priority 
                          description={game.descriptionMedium}
                          buttons={[
                            { action: 'play', label: 'Play' },
                            { action: 'archive', label: 'Archive' }
                          ]}
                        />
                      ))
                    }
                  </DailyGameTileContainer>
                </Block>
                <Block>
                  {
                    If(isPromoActive, 
                      () => (
                        <PromoTile 
                          eventAction='upsell_sale'
                          eventTagName='upsell_sale_featured_pod_mobile'
                        />
                      )).Else(() => (
                      <DailyArchiveUpsellTile
                        game={props.dailyJigsaw}
                        isPremiumUser={authState?.user?.isPremiumUser}
                      />
                    )).EndIf()
                  }
                </Block>
                <Block>
                  <DailyGameTileContainer>
                    {
                      For(props.games.slice(2,6), (game, index: number) => (
                        <DailyGameTile
                          key={index} 
                          game={game} 
                          color={game.highlightColor}
                          priority 
                          description={game.descriptionMedium}
                          buttons={[
                            { action: 'play', label: 'Play' },
                            { action: 'archive', label: 'Archive' }
                          ]}
                        />
                      ))
                    }
                  </DailyGameTileContainer>
                </Block>
              </Slot>

              {/* Desktop */}
              <Slot name='desktop'>
                {
                  If(!isPremiumUser, () => (
                    <Block>
                      <PlaywireUnit useAdFallback unit='leaderboard_atf' />
                    </Block>
                  )).EndIf()
                }

                <Title h2 size5 className='--textCenter'>Featured Games</Title>
                <Block>
                  <DailyGameTileContainer>
                    {
                      For(props.games.slice(0,3), (game, index: number) => (
                        <DailyGameTile
                          key={index} 
                          game={game} 
                          color={game.highlightColor}
                          priority 
                          description={game.descriptionMedium}
                          buttons={[
                            { action: 'play', label: 'Play' },
                            { action: 'gamelanding', label: 'Archive' }
                          ]}
                        />
                      ))
                    }
                  </DailyGameTileContainer>
                </Block>
                {
                  If(authState?.ready, () => (
                    <Block>
                      {
                        If(isPromoActive, 
                          () => (
                            <PromoTile 
                              eventAction='upsell_sale'
                              eventTagName='upsell_sale_featured_pod_desktop'
                            />
                          )).Else(() => (
                          <DailyArchiveUpsellTile
                            game={props.dailyJigsaw}
                            isPremiumUser={authState?.user?.isPremiumUser}
                          />
                        )).EndIf()
                      }
                    </Block>
                  )).EndIf()
                }
                <Block>
                  <DailyGameTileContainer>
                    {
                      For(props.games.slice(3, 6), (game, index: number) => (
                        <DailyGameTile
                          key={index} 
                          game={game} 
                          color={game.highlightColor}
                          description={game.descriptionMedium}
                          buttons={[
                            { action: 'play', label: 'Play' },
                            { action: 'gamelanding', label: 'Archive' }
                          ]}
                        />
                      ))
                    }
                  </DailyGameTileContainer>
                </Block>
              </Slot>
            </PerDevice>
          </Block>

          {/* Downloadable Games */}
          <PerDevice className='Block' useDisplay>
            <Slot name='desktop'>
              {
                If(!isPremiumUser, () => (
                  <Block>
                    <PlaywireUnit useAdFallback unit='leaderboard_btf' />
                  </Block>
                )).EndIf()
              }
              
              <Title h2 size5 className='--textCenter'>Featured Downloadable Games</Title>
              <Block>
                <FlexDailyGameTileContainer>
                  {
                    If(props.downloadGames.length > 0, () => (
                      <MediaDailyGameTile
                        game={props.downloadGames[ 0 ]} 
                        featured
                        useShortDescription
                      />
                    )).EndIf()
                  }
                  {
                    If(props.downloadGames.length > 1, () => (
                      <MediaDailyGameTile
                        game={props.downloadGames[ 1 ]}
                        tall
                        useShortDescription
                      />
                    )).EndIf()
                  }
                </FlexDailyGameTileContainer>
              </Block>

              {
                If(!isPremiumUser, () => (
                  <Block>
                    <DownloadsUpsellTile eventLocation='bottom_page_upsell' />
                  </Block>
                )).EndIf()
              }
            </Slot>
          </PerDevice>

          {/* {
            If(authState?.ready && authState?.user && !authState.user?.isPremiumUser, () => (
              <Block className='IndexPage__BonusDailyGames'>
                <Title h2 size5 className='--textCenter --marSm2__b'>
                  Free Archive Games - DO NOT DEPLOY THIS IS NOT HOOKED UP
                </Title>
                <p className='--textCenter --isSmallText --marMd0__b'>Play a selection of free games from our expansive backlog. <Link href='/unlimited'><a className='IndexPage__BonusDailyGamesUpsell'>Subscribe here</a></Link> to access even more games.</p>
                <DailyGameTileContainer quad>
                  {
                    For(props.bonusDailyGames, (bonusDailyGame) => (
                      <BonusDailyGameTile game={bonusDailyGame.game} archiveDate={bonusDailyGame.archiveDate} color={bonusDailyGame.game.highlightColor} />
                    ))
                  }
                </DailyGameTileContainer>
              </Block>
            )).EndIf()
          } */}
          
        </Container>
      </section>

    </FluidLayout>
  );
});

export async function getServerSideProps () {
  // Fetch games
  let games: ExtendedGameData[];
  let downloadGames: ExtendedGameData[];
  let featuredDailyGame: FullGameData;
  let dailyJigsaw: FullGameData;
  let upsell: DailyGameUpsell;

  try {
    featuredDailyGame = await HomeModel.getIndexHeroGame();
    games = await HomeModel.getFeaturedDailyGames();
    downloadGames = await HomeModel.getFeaturedDownloadGames();
    dailyJigsaw = await GamesModel.getBySlug('gamelanding/jigsawpuzzles');
    upsell = await GamesModel.getDailyUpsell();
  } catch (err) {
    throw err;
  }

  // TODO this needs to be hooked up/set based on actual unlocked games
  const today = new Date();

  const bonusDailyGames = games.slice(0,4).map((game, i) => {
    return {
      game,
      archiveDate: dateToDailyFields(addWeeks(today, 0 - i - 1))
    };
  });

  // Gather props
  const props: IndexPageProps = {
    games,
    downloadGames,
    featuredDailyGame,
    dailyJigsaw,
    upsell,
    bonusDailyGames
  };

  return {
    props,
  };
}
